<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BCardCode,
    },
    directives: {
      Ripple,
    },
    mounted() {},
    data() {
      return {
        bancos: {
          104: 'Caixa Econômica Federal',
        },
        table: {
          items: [],
          fields: [
            {
              key: 'nomeDoTerceiroFavorecido',
              label: 'Nome Favorecido',
              sortable: true,
            },
            { key: 'codigoBancoDestino', label: 'Banco' },
            {
              key: 'codigoAgenciaDestino',
              label: 'Agência',
            },
            {
              key: 'contaCorrenteDestino',
              label: 'Conta Corrente',
            },
            {
              key: 'valorLancamento',
              label: 'Valor',
              formatter: this.utils().formatarValor,
            },
            { key: 'numeroDocumentoBanco', label: 'Documento' },
            { key: 'ocorrencias', label: 'Ocorrências', sortable: true },
          ],
          isBusy: false,
          sort: {
            by: '',
            desc: false,
            direction: 'asc',
          },
          styles: {
            status: [
              {
                GRPEPago: 'success',
                GRPEGerado: 'warning',
                GRPEExpirado: 'info',
              },
            ],
          },
        },
        pesquisar: {
          pagination: {
            currentPage: 1,
            total: 0,
            perPage: 10,
            options: [10, 25, 50, 100],
          },
        },
      }
    },
    methods: {
      files() {
        return {
          onInput: (event) => {},
          read: (cb) => {
            const file = this.$refs.file.files[0]
            if (!file) this.$message.error('Informe o arquivo de importação')

            const reader = new FileReader()
            reader.onload = () => {
              cb(reader.result)
            }
            reader.readAsText(file)
          },
        }
      },
      tables() {
        return {
          preencher: async (arquivo) => {
            const response = await this.pagamentos().importar(arquivo)
            const segmentosA = response.filter((res) => res.codigoSegmento === 'A')

            this.table.items = segmentosA
          },
        }
      },
      pagamentos() {
        return {
          importar: async (arquivo) => {
            return (await useJwt.post(`pagamento/upload`, { retorno: arquivo })).data
          },
        }
      },
      utils() {
        return {
          formatarValor: (number) => {
            const trimmedNumber = number.replace(/^0+/, '')
            const result = parseFloat(trimmedNumber) / 100
            const formattedResult = result.toFixed(2)
            return formattedResult
          },
        }
      },
    },
  }
</script>
<template>
  <div>
    <b-card-code title="Retorno" no-body>
      <b-card-body>
        <validation-observer ref="formImport">
          <b-form>
            <b-row>
              <b-col sm="3">
                <b-form-group label="Arquivo de Importação" label-for="competencia">
                  <validation-provider #default="{ validate, errors }" name="Arquivo de importação" rules="required">
                    <b-form-file
                      class="mr-1"
                      ref="file"
                      accept=".txt"
                      placeholder="Escolha um arquivo"
                      @change="($event) => files().onInput($event)"
                      drop-placeholder="Deixe seu arquivo aqui..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 w-100 alinhamentoInputImportMargemBtn"
                  @click.prevent="files().read((arquivo) => tables().preencher(arquivo))"
                >
                  Importar Arquivo
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-table
          v-show="table.items.length > 0"
          id="retorno"
          striped
          responsive
          class="position-relative"
          :per-page="pesquisar.pagination.perPage"
          :items="table.items"
          :fields="table.fields"
          :sort-by.sync="table.sort.by"
          :sort-desc.sync="table.sort.desc"
          :sort-direction="table.sort.direction"
          :busy="table.isBusy"
          :current-page="pesquisar.pagination.currentPage"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #cell(codigoBancoDestino)="row">
            <b-badge pill variant="info">
              {{ bancos[row.item.codigoBancoDestino] }}
            </b-badge>
          </template>
          <template #cell(numeroDocumentoBanco)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.numeroDocumentoBanco) }}
            </div>
          </template>
          <template #cell(codigoAgenciaDestino)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.codigoAgenciaDestino) }}-<span style="color: black">{{ row.item.dvAgenciaDestino }}</span>
            </div>
          </template>
          <template #cell(contaCorrenteDestino)="row">
            <div style="font-weight: 500">
              {{ parseInt(row.item.contaCorrenteDestino) }}-<span style="color: black">{{ row.item.dvContaDestino }}</span>
            </div>
          </template>
          <template #cell(ocorrencias)="row">
            <div style="display: flex; flex-direction: column">
              <template v-for="ocorrencia in row.item.ocorrencias">
                <b-badge pill style="flex-grow: 0; margin: 10px" :variant="ocorrencia.codigo == '00' ? 'success' : 'danger'">
                  {{ ocorrencia.descricao }}
                </b-badge>
              </template>
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<style lang="scss" scoped></style>
