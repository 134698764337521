<template>
  <b-card-code title="Remessa" no-body>
    <b-card-body>
      <b-row>
        <!-- Exercício  -->
        <b-col md="2">
          <b-form-group label="Exercício" label-for="exercicio">
            <b-form-select name="exercicio" v-model="filtro.exercicio" :options="exercicioOpcoes"></b-form-select>
          </b-form-group>
        </b-col>

        <!-- Competência  -->
        <b-col md="2">
          <b-form-group label="Competência" label-for="competencia">
            <b-form-select name="competencia" v-model="filtro.competencia" :options="competenciaOpcoes"> </b-form-select>
          </b-form-group>
        </b-col>

        <!-- Actions -->
        <b-col md="auto">
          <b-button
            :disabled="isBusy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 25px; width: 160px"
            @click.prevent="carregarGrid"
          >
            {{ !isBusy ? 'Pesquisar' : '' }}
            <b-spinner small v-if="isBusy" />
          </b-button>
        </b-col>

        <!-- Remessa -->
        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            style="margin-top: 25px; width: 160px"
            variant="danger"
            @click.prevent="authESalve"
          >
            Gerar Remessa
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- Tabela -->
    <b-table
      id="tabelaPagamento"
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative rounded"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :current-page="pesquisar.currentPage"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <!-- Campo Nº Contrato -->
      <template #cell(codigo)="row">
        {{ String(row.item.codigo).padStart(6, '0') }}
      </template>

      <!-- Campo Nome Tomador -->
      <template #cell(nomeCompleto)="row">
        <div>{{ row.item.nomeCompleto }}</div>
        <div>{{ row.item.matricula }}</div>
      </template>

      <!-- Campo Valor Banco -->
      <template #cell(banco)="row">
        <div>
          {{ row.item.banco ? row.item.banco : 'Banco não cadastrado' }}
        </div>
      </template>

      <!-- Campo Valor Agência -->
      <template #cell(agencia)="row">
        <div>
          {{
            row.item.dadoBancarioAgencia
              ? String(row.item.dadoBancarioAgencia) + ' - ' + String(row.item.dadoBancarioAgenciaDV)
              : 'Agência não cadastrada'
          }}
        </div>
      </template>

      <!-- Campo Valor Conta -->
      <template #cell(conta)="row">
        <div>
          {{
            row.item.dadoBancarioConta
              ? String(row.item.dadoBancarioConta) + ' - ' + String(row.item.dadoBancarioContaDV)
              : 'Conta não cadastrada'
          }}
        </div>
      </template>

      <!-- Campo Valor Tipo de Contrato -->
      <template #cell(tipoContrato)="">
        <div>Novo</div>
      </template>

      <!-- Campo Valor Montante -->
      <template #cell(valorContrato)="row">
        {{ formatarValor(row.item.valorContrato) }}
      </template>
    </b-table>

    <!-- Paginação -->
    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- Modal de Senha -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card-code>
</template>

<script>
  //validacao
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValor, isLetter } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import { AlertOctagonIcon } from 'vue-feather-icons'
  import axios from 'axios'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      Cleave,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarValor,
      BCardCode,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        filtro: { exercicio: '', competencia: '', valorPatrimonio: 0.0, valorMontante: 0.0 },
        isBusy: false,
        number: {},
        formatarValor,
        isLetter,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        pesquisar: { perPage: 25, currentPage: 1 },
        exercicioOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '2025', text: '2025' },
          { value: '2024', text: '2024' },
          { value: '2023', text: '2023' },
          { value: '2022', text: '2022' },
          { value: '2021', text: '2021' },
          { value: '2020', text: '2020' },
          { value: '2019', text: '2019' },
          { value: '2018', text: '2018' },
          { value: '2017', text: '2017' },
          { value: '2016', text: '2016' },
          { value: '2015', text: '2015' },
        ],
        competenciaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        acesso: {},

        //Campos tabela
        fields: [
          { key: 'codigo', label: 'Nº Contrato', sortable: true },
          { key: 'nomeCompleto', label: 'Nome/Matricula', sortable: true },
          { key: 'banco', label: 'Banco', sortable: true },
          { key: 'agencia', label: 'Agência' },
          { key: 'conta', label: 'Conta' },
          { key: 'tipoContrato', label: 'Tipo de Contrato' },
          { key: 'valorContrato', label: 'Valor do Contrato' },
        ],
        items: [],
        statusOpcoes: [
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Gerado', text: 'Gerado' },
          { value: 'Confirmado', text: 'Confirmado' },
        ],
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
            Quitado: 'info',
          },
        ],

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        striped: true,
      }
    },
    mounted() {
      this.filtro.exercicio = new Date().getFullYear()
      this.filtro.competencia = new Date().getMonth() + 1
    },
    methods: {
      authESalve() {
        this.$refs['modal-auth-senha'].show()
        // if (this.items.length > 0) {
        //   if ((this.userData.role = 'master' || this.userData.GrupoAcesso.FinanceiroPagamento)) {
        //     this.filtro.mode = 'authESalve'
        //   } else this.$message.error('O usuário não tem permissão para gerar remessa, verifique o grupo de acesso!')
        // } else this.$message.error('É necessário listar qual competência irá gerar a remessa!')
      },
      async autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          await this.gerarRemessa()
        }
      },
      async gerarRemessa() {
        let exercicio = formatarParaNumber(this.filtro.exercicio)
        let mes = formatarParaNumber(this.filtro.competencia)
        let competencia = exercicio * 100 + mes

        const data = await useJwt.post('boleto/geraRemessa', {
          institutoId: this.userData.institutoSelecionado,
          competencia,
        })
        var link = document.createElement('a')
        link.download = 'remessa.txt'
        var blob = new Blob([data.data.arquivo], { type: 'text/plain' })
        link.href = window.URL.createObjectURL(blob)
        link.click()
      },
      downloadArquivoRemessa(binario) {
        // Converte a string binária em um array de bytes
        let binaryData = atob(binario)

        // Cria um array de bytes
        let byteArray = new Uint8Array(binaryData.length)

        // Preenche o array com os bytes convertidos
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i)
        }

        // Cria um Blob (objeto binário) a partir do array de bytes
        let blob = new Blob([byteArray])

        // Cria uma URL temporária para o Blob
        let url = URL.createObjectURL(blob)

        // Cria um link para fazer o download do arquivo
        let link = document.createElement('a')
        link.href = url

        // Define o nome do arquivo
        let vDataHoraGeracao = new Date()
        // let nomeArquivo = vDataHoraGeracao.toISOString().replace(/[-T:]/g, "").slice(0, -5) + ".txt";

        let nomeArquivo = 'remessa.txt'
        link.download = nomeArquivo

        // Simula um clique no link para iniciar o download
        link.click()

        // Limpa a URL temporária
        URL.revokeObjectURL(url)
      },
      carregarGrid() {
        this.isBusy = true
        let exercicio = formatarParaNumber(this.filtro.exercicio)
        let mes = formatarParaNumber(this.filtro.competencia)
        let competencia = exercicio * 100 + mes
        let institutoId = this.userData.institutoSelecionado
        let parametros = {
          institutoId: institutoId,
          competencia: competencia,
        }

        useJwt
          .pesquisar('boleto/pesquisarBoleto', parametros)
          .then((response) => {
            this.isBusy = false
            this.items = response.data
            this.totalRows = response.data.length
            if (!response.data.dados || response.data.dados.length == 0) this.$message.error('Nenhum registro encontrado!')
          })
          .catch((error) => {
            this.isBusy = false
            console.error(error)
          })
      },
    },
  }
</script>
