<template>
  <div>
    <b-tabs pills>
      <b-tab title="Remessa" active>
        <b-card-text>
          <RemessaBoletoEnvio />
        </b-card-text>
      </b-tab>
      <b-tab title="Retorno">
        <b-card-text>
          <RemessaBoletoRetorno />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import RemessaBoletoEnvio from './envio/RemessaBoletoEnvio.vue'
  import RemessaBoletoRetorno from './retorno/RemessaBoletoRetorno.vue'

  export default {
    components: {
      RemessaBoletoEnvio,
      RemessaBoletoRetorno,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
  }
</script>
